import React from 'react'
import {
  ReactiveBase,
  DataSearch,
  ReactiveList,
  ResultList,
  SelectedFilters,
} from '@appbaseio/reactivesearch'
import Layout from '../components/Layout'
import * as styles from '../assets/css/search.module.css'

const Search = () => {
  const osUsername = process.env.OPENSEARCH_READ_ONLY_USERNAME
  const osPassword = process.env.OPENSEARCH_READ_ONLY_PASSWORD
  const searchCred = `${osUsername}:${osPassword}`
  const searchUrl = process.env.OPENSEARCH_ENDPOINT.includes('https://')
    ? process.env.OPENSEARCH_ENDPOINT
    : 'https://' + process.env.OPENSEARCH_ENDPOINT
  const searchIndex =
    process.env.OPENSEARCH_INDEX || process.env.SEARCH_INDEX || 'seaside'
  return (
    <Layout>
      <ReactiveBase app={searchIndex} credentials={searchCred} url={searchUrl}>
        <div className={styles.col}>
          <DataSearch
            dataField={['name', 'address', 'architectNames']}
            componentId='Search'
          />
          <SelectedFilters />
          <ReactiveList
            componentId='SearchResult'
            dataField='name'
            size={10}
            pagination
            react={{
              and: 'Search',
            }}
            render={({ data }) => (
              <ReactiveList.ResultListWrapper>
                {data.map((item) => (
                  <ResultList
                    href={`/structures/${item.uid}`}
                    target='_self'
                    id={item._id}
                    key={item._id}
                  >
                    <ResultList.Content>
                      <ResultList.Title>
                        <div
                          className={styles.structureName}
                          dangerouslySetInnerHTML={{
                            __html: item.name,
                          }}
                        />
                      </ResultList.Title>

                      <ResultList.Description>
                        <div>
                          <dl className={styles.structureList}>
                            <dt>Street Address:</dt>
                            <dd>{item.address}</dd>
                            <dt>Architects:</dt>
                            <dd>{item.architectNames}</dd>
                            <dt>Start of Construction:</dt>
                            <dd>{item.constructionStart}</dd>
                            <dt>End of Construction:</dt>
                            <dd>{item.constructionEnd}</dd>
                            <dt>Code Type:</dt>
                            <dd>{item.codeType}</dd>
                            <dt>Building Type:</dt>
                            <dd>{item.buildingType}</dd>
                            <dt>Longitude</dt>
                            <dd>{item.longitude}</dd>
                            <dt>Latitude</dt>
                            <dd>{item.latitude}</dd>
                          </dl>
                        </div>
                      </ResultList.Description>
                    </ResultList.Content>
                  </ResultList>
                ))}
              </ReactiveList.ResultListWrapper>
            )}
          />
        </div>
      </ReactiveBase>
    </Layout>
  )
}

export default Search
